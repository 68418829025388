<template>
    <b-card>
        <h1 class="text-center">{{ $t('login.title') }}</h1>
        <b-form class="login" @submit.prevent="login">
            <b-form-group
                label-for="email">
                <b-form-input
                    v-model="email"
                    id="email"
                    placeholder="E-mail"
                    required
                    type="email"
                ></b-form-input>
            </b-form-group>
            <b-form-group
                label-for="password">
                <b-form-input
                    v-model="password"
                    id="password"
                    placeholder="Heslo"
                    required
                    type="password"
                ></b-form-input>
            </b-form-group>
            <b-form-group>
                <b-button class="col-12 " type="submit" variant="primary">{{ $t('login.login') }}</b-button>
            </b-form-group>
            <router-link :to="{name: 'password-reset'}" class=""><b>{{ $t('login.passwdForget') }}</b></router-link>
        </b-form>
    </b-card>
</template>

<script>
export default {
    name: "login",
    data() {
        return {
            email: "",
            password: ""
        }
    },
    methods: {
        login: function () {
            let email = this.email;
            let password = this.password;
            this.$store.dispatch('login', {email, password})
                .then(
                    () => {
                      if (this.role === 'meetings-only') {
                        this.$router.push('/meetings');
                      } else {
                        this.$router.push('/projects');
                      }
                    }
                )
                .catch(() =>
                    this.$eventHub.$emit('openMessageController', 'error', this.$t('messages.wrong_login'))
                )
        }
    },
  computed: {
    role() {
      try {
        return this.$store.getters.user.permission;
      } catch (err) {
        return false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.card {
    width: 100%;
    max-width: 400px;
}
</style>
